import React from 'react';
import css from './ListingPage.module.css';
import { partsConfig } from '../../config/parts-config';
import { brandsConfig } from '../../config/brands-config';

const BIKE_REQUIRED_FIELDS = ['frameSize', 'condition', 'category', 'wheelSize'];
const PARTS_REQUIRED_FIELDS = ['subCategory', 'year'];

function SectionBasicInformation(props) {
  const { filters, publicData, intl } = props;
  const { category, subCategory, listingType, brand } = publicData;

  const isBike = listingType === 'bike';

  const partAttributes = () => {
    const subCategoryAttributes = partsConfig
      ?.find(config => config.id === listingType)
      ?.config?.options?.find(cat => cat.key === category)
      ?.subcategories?.find(subCat => subCat.key === subCategory)
      ?.specifications?.map(specification => specification.id);

    const categorySpecifications = partsConfig
      ?.find(config => config.id === listingType)
      ?.config?.options?.find(cat => cat.key === category)
      ?.specifications?.map(specification => specification.id);

    const specifications = subCategoryAttributes || categorySpecifications;

    return PARTS_REQUIRED_FIELDS.concat(specifications);
  };

  const pickExtendedData = (filterConfig, intl) => (rows, key) => {
    const publicDataValue = publicData[key];
    if (publicDataValue) {
      const filterIfItExists = filterConfig.find(f => f.id === key);
      const filterOptions = filterIfItExists?.config?.options || [];

      const translationGroup = filterIfItExists?.translationGroup;
      const skipItemPageTranslations = filterIfItExists?.skipItemPageTranslations;

      const value =
        translationGroup && !skipItemPageTranslations
          ? intl.formatMessage({ id: `${translationGroup}.${publicDataValue}` })
          : filterOptions.find(o => o.key === publicDataValue)?.label || publicDataValue;

      const label = filterIfItExists?.label || `${key.charAt(0).toUpperCase()}${key.slice(1)}`;

      return rows.concat({ key, value, label });
    }
    return rows;
  };

  const subCategoryConfig = () =>
    partsConfig
      .find(c => c.id === listingType)
      ?.config?.options?.find(cat => cat.key === category)
      ?.subcategories?.find(subCat => subCat.key === subCategory)?.specifications;

  const categorySpecificationsConfig = () =>
    partsConfig.find(c => c.id === listingType)?.config?.options?.find(cat => cat.key === category)?.specifications;

  const specificationFilters = listingType === 'bike' ? undefined : (subCategoryConfig() || categorySpecificationsConfig());

  const buildPartsAttributes = (config, intl) => (rows, key) => {
    const publicDataValue = publicData[key];

    if (config === undefined) return [];

    if (key === 'subCategory') {
      const label = undefined;
      const value = intl.formatMessage({ id: `sub_categories.${subCategory || category}` });

      return rows.concat({ key, value, label });
    }
    if (publicDataValue) {
      const option = config.find(c => c.id === key);

      const translationGroup = option?.translationGroup;
      const skipItemPageTranslations = option?.skipItemPageTranslations;

      const value =
        translationGroup && !skipItemPageTranslations
          ? intl.formatMessage({ id: `${translationGroup}.${publicDataValue}` })
          : option?.config?.options?.find(o => o.key === publicDataValue)?.label || publicDataValue;

      const label = option?.label || `${key.charAt(0).toUpperCase()}${key.slice(1)}`;

      return rows.concat({ key, value, label });
    }

    return rows;
  };

  const brands = brandsConfig[listingType] || brandsConfig[subCategory] || undefined;
  const brandValue = brands?.config?.options?.find(b => b.value === brand)?.label
  const brandAttributes = [{ key: 'brand', value: brandValue || '' }];

  const existingExtendedData = !isBike
    ? partAttributes()
        .reduce(buildPartsAttributes(specificationFilters, intl), [])
        .concat(brandAttributes)
    : BIKE_REQUIRED_FIELDS.reduce(pickExtendedData(filters, intl), []).concat(brandAttributes);

  return (
    <>
      <div className={css.listingPageGroupLabelWrapper}>
        <label className={css.listingPageGroupLabel}>
          {intl.formatMessage({ id: 'listing_page.basic_information.label' })}
        </label>
      </div>
      {existingExtendedData.map(detail => (
        <div key={`${detail.key}_base`}>
          <div key={detail.key} className={css.sectionDescriptionPanel}>
            <label className={css.basicInformationLabel}>
              {intl.formatMessage({ id: `config.labels.${detail.key}` })}
            </label>
            <span className={css.sectionDescriptionPanelValue}>{detail.value}</span>
          </div>
          <div key={`${detail.key}_divider`} className={css.divider} />
        </div>
      ))}
    </>
  );
}

export default SectionBasicInformation;
